































import {Component, Prop, Vue} from "vue-property-decorator";
import axios from "axios";
import TextInput from "@/components/form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";

@Component({
  components: {TextInput, FormButton},
  props: ["banner"],
})
export default class ControlPanelNewsBanner extends Vue {
  @Prop()
  public banner: any;

  public editTitle = this.banner.title;
  public editMessage = this.banner.message;
  public editLink = this.banner.link;
  public editLinkTitle = this.banner.link_title;

  public expanded = false;

  public toggle() {
    if (!this.banner.enabled && (!this.editTitle || !this.editMessage || !this.editLink || !this.editLinkTitle)) {
      if (!confirm("Some fields of this news banner are empty. Do you want to enable the banner anyway?")) {
        return;
      }
    }
    axios.patch("/controlpanel/news-banners/" + this.banner.id, {enabled: !this.banner.enabled}).then(() => {
      this.$emit("toggled", this.banner);
    });
  }

  public toggleExpand() {
    this.expanded = !this.expanded;
  }

  public save() {
    const changedBanner = {
      id: this.banner.id,
      title: this.editTitle,
      message: this.editMessage,
      link: this.editLink,
      link_title: this.editLinkTitle,
    };
    axios.patch("/controlpanel/news-banners/" + this.banner.id, changedBanner).then(() => {
      this.toggleExpand();
      this.$emit("edit", changedBanner);
    });
  }
}
