










import {Component, Vue} from "vue-property-decorator";
import ControlPanelNewsBanner from "./news-banners/ControlPanelNewsBanner.vue";
import axios from "axios";

@Component({
  components: {
    ControlPanelNewsBanner,
  },
})
export default class NewsBannerControlPanel extends Vue {
  public banners: any[] = [];

  public created() {
    axios.get("/controlpanel/news-banners").then((res) => {
      this.banners = res.data.items;
    });
  }

  public toggled(banner: any) {
    this.banners.find((b) => b.id === banner.id).enabled = !banner.enabled;
  }

  public edit(changedBanner: any) {
    const original = this.banners.find((b) => b.id === changedBanner.id);
    original.title = changedBanner.title;
    original.link = changedBanner.link;
    original.message = changedBanner.message;
    original.link_title = changedBanner.link_title;
  }
}
